/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { devices } from '@/UI/Styles/Queries';

export const MapContainer = styled.div`
  display: none;
`;

export const CompanyInfoContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #fafafa;
  @media ${devices['no-mobile']} {
    padding-top: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
  .toggleButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${devices.XLarge} {
      position: relative;
      height: 40px;
    }
  }

  @media ${devices.XLarge} {
    ${MapContainer} {
      display: block;
    }
  }
`;

export const InfoWrapper = styled.div<{ collapsed: boolean; hasRrss: boolean; hasDescription: boolean }>`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media ${devices.XLarge} {
    gap: 50px;
    justify-content: space-between;
    padding: 0px;
  }

  transition: ${({ collapsed }) =>
    collapsed ? 'max-height 0.5s cubic-bezier(0, 1, 0, 1)' : 'max-height 1s ease-in-out'};
`;

export const ButtonToggleWrapper = styled.button<{ collapsed: boolean }>`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 100%;
  border: none;
  box-shadow: 1px 1px 3px #d2d2d2;
  cursor: pointer;
  height: 40px;
  margin: 16px 0;
  transform: rotate(${({ collapsed }) => (collapsed ? '90deg' : '-90deg')});
  width: 40px;
  z-index: 2;

  .collapsedButton {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media ${devices.XLarge} {
    position: absolute;
    left: 50%;
    top: 2px;
  }
`;
