import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Location, Phone, Clock, Home } from '@agendapro/emerald-icons';

import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import { Paragraph, WhatsAppLink, devices } from '@/UI';
import * as St from './Map.styles';
import { MapTypes } from './Map.types';
import { CopyClipboard } from '@/components/CopyClipboard';
import { useServiceProviders } from '@/services/serviceProviders';
import usePageContext from '@/hooks/usePageContext';
import { SliderAvatarEmployees } from '../ServicesByLocation/SliderAvatarEmployees';
import { useCompanyBookingInfo } from '@/services/company';

const DynamicLocationTimes = dynamic(
  () => import('@/entries/Locations/components/LocationTimes').then((mod) => mod.LocationTimes),
  { ssr: false },
);

const DynamicTooltip = dynamic(() => import('@agendapro/emerald/v2').then((mod) => mod.Tooltip));

export const Map: FC<MapTypes> = (props) => {
  const {
    image,
    address,
    phone,
    times,
    collapsed,
    outcall,
    hasDescription,
    hasBanner,
    viewType,
    locationId,
    whatsappUrl,
    whatsappActive,
    secondaryPhone,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery({ query: devices.XLarge });

  const { data: providersByLocation } = useServiceProviders(locationId);
  const { currentLocation, companySlug, selectedServices, isProviderRoute } = usePageContext();
  const { data: companyBookingInfo } = useCompanyBookingInfo(companySlug);

  const handlePhoneNumber = () => {
    if (phone && secondaryPhone) {
      return `${phone} - ${secondaryPhone}`;
    }

    if (secondaryPhone) {
      return secondaryPhone;
    }

    if (phone) {
      return phone;
    }

    return null;
  };

  const queryPlaceId: string = currentLocation?.googleMapsPlaceId
    ? `&query_place_id=${currentLocation?.googleMapsPlaceId}`
    : '';

  const mapRedirectAdress: string = `https://www.google.com/maps/search/?api=1&query=${currentLocation?.redirectAddress}${queryPlaceId}`;

  return (
    <St.Wrapper data-testid="mapContainer" hasDescription={hasDescription} hasBanner={hasBanner} viewType={viewType}>
      <a href={mapRedirectAdress} target="_blank" rel="noreferrer">
        <St.WrapperImage
          collapsed={collapsed}
          hasBanner={hasBanner}
          hasDescription={hasDescription}
          image={image}
          outcall={outcall}
          viewType={viewType}
        />
      </a>

      <St.WrapperContent hasDescription={hasDescription} viewType={viewType} hasBanner={hasBanner}>
        {outcall && (
          <>
            <St.ItemDetailContainer>
              <Home size={20} color={theme.palette.textDefault} className="icon" />
              <Paragraph weight="light" color={theme.palette.textSubtle}>
                {t('HOME_SERVICE')}
              </Paragraph>
            </St.ItemDetailContainer>
          </>
        )}
        {address && (
          <>
            <St.ItemDetailContainer>
              <Location size={20} color={theme.palette.textDefault} className="icon" />
              <St.TextBox>
                <a href={mapRedirectAdress} target="_blank" rel="noreferrer">
                  <Paragraph weight="light" size="paragraph" color={theme.palette.textSubtle}>
                    {address}
                  </Paragraph>
                </a>
              </St.TextBox>
            </St.ItemDetailContainer>
          </>
        )}
        {(phone || secondaryPhone) && (
          <St.ItemDetailContainer>
            <Phone size={20} color={theme.palette.textDefault} className="icon" />
            <CopyClipboard text={phone}>
              <St.StyledPhone weight="light" color={theme.palette.textSubtle}>
                {handlePhoneNumber()}
              </St.StyledPhone>
            </CopyClipboard>
          </St.ItemDetailContainer>
        )}
        {whatsappActive && whatsappUrl && (
          <WhatsAppLink
            whatsAppUrl={whatsappUrl}
            text={providersByLocation?.length === 1 ? t('CONTACT_ME_WHATSAPP') : ''}
          />
        )}
        {times && !!times?.length && isDesktop && (
          <DynamicTooltip
            position="bottom-start"
            content={<DynamicLocationTimes className="TimeContainer" locationsTimes={times} />}
          >
            <St.WrapperTime>
              <Clock size={20} color={theme.palette.grays80} className="icon" />
              <Paragraph weight="light" size="paragraph" color={theme.palette.textSubtle} className="underline">
                {t('SEE_SCHEDULE')}
              </Paragraph>
            </St.WrapperTime>
          </DynamicTooltip>
        )}
        {!!times.length && !isDesktop && <DynamicLocationTimes className="TimeContainer" locationsTimes={times} />}
        {!isProviderRoute && companyBookingInfo?.showProviders !== 2 && isDesktop && (
          <SliderAvatarEmployees hasSelectedServices={!!selectedServices} />
        )}
      </St.WrapperContent>
    </St.Wrapper>
  );
};
