import { FC, useEffect, useState } from 'react';
import { EmeraldThemeProvider, Tooltip } from '@agendapro/emerald/v2';
import { useTranslation } from 'react-i18next';

import { CopyClipboardProps } from './CopyClipboard.types';
import { copy, to } from '@/utils';
import * as St from './CopyClipboard.styles';

export const CopyClipboard: FC<CopyClipboardProps> = ({ children, text }) => {
  const { t } = useTranslation();
  const [openTooltip, setOpenTooltip] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);

  const copyClipboard = async () => {
    const [err] = await to(copy(text));

    if (!err) {
      setOpenTooltip(true);
    }
  };

  useEffect(() => {
    setDocumentLoaded(true);
  }, []);

  if (!documentLoaded) {
    return <>{children}</>;
  }

  return (
    <EmeraldThemeProvider>
      <Tooltip
        content={<St.TooltipContent>{t('COPIED')}</St.TooltipContent>}
        flip
        onDismiss={() => setOpenTooltip(false)}
        open={openTooltip}
        position="top"
        timeout={2000}
      >
        <span onClick={copyClipboard} aria-hidden="true">
          {children}
        </span>
      </Tooltip>
    </EmeraldThemeProvider>
  );
};
