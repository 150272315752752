import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { Skeleton } from '@/UI';
import { AvatarContainer, SliderAvatarEmployeesSkeletonContainer } from './SliderAvatarEmployeesSkeleton.styles';

const SliderAvatarEmployeesSkeleton = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.sm });
  const avatarSize = isDesktop ? 55 : 52;
  const textWidth = isDesktop ? 54 : 43;

  return (
    <>
      <Skeleton style={{ height: isDesktop ? 22 : 19, width: 250, marginBottom: isDesktop ? 24 : 17 }} />
      <SliderAvatarEmployeesSkeletonContainer>
        {[...Array(isDesktop ? 8 : 5).keys()].map((el) => (
          <AvatarContainer key={el}>
            <Skeleton style={{ height: avatarSize, width: avatarSize, borderRadius: '100%', marginBottom: 5 }} />
            <Skeleton style={{ height: 8, width: textWidth, marginBottom: 4 }} />
            {isDesktop && <Skeleton style={{ height: 8, width: textWidth }} />}
          </AvatarContainer>
        ))}
      </SliderAvatarEmployeesSkeletonContainer>
    </>
  );
};

export default SliderAvatarEmployeesSkeleton;
