import { FC, useRef } from 'react';

import { BannerLogo, ViewMore } from '@/UI';
import { CompanyInfoProps } from './types';
import * as St from './CompanyInfo.styles';
import { ShowUp } from '@/components';
import { SliderAvatarEmployees } from '../ServicesByLocation/SliderAvatarEmployees';
import { useCompanyBookingInfo } from '@/services/company';
import { usePageContext } from '@/hooks';

export const CompanyInfo: FC<CompanyInfoProps> = ({
  logo,
  banner,
  collapsed,
  rrss,
  companyDescription = '',
  locationDescription = '',
  isLoading,
  companyName,
  mobileAddress,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { companySlug, selectedServices } = usePageContext();

  const { data: companyBookingInfo } = useCompanyBookingInfo(companySlug);

  return (
    <St.CompanyInfoContainer collapsed={collapsed} hasBanner ref={containerRef}>
      <St.HeroWrapper>
        <BannerLogo banner={banner} logo={logo} />
      </St.HeroWrapper>
      <ViewMore
        textContent={companyDescription}
        textContentSecondary={locationDescription}
        maxNumOfLines={2}
        arial-label="companyDescription"
        className="companyDescription"
        data-testid="companyDescription"
        isLoading={isLoading}
        rrss={rrss}
        companyName={companyName}
        showReviews
        logo={logo}
      />
      {mobileAddress && <ShowUp untilTo="XLarge">{mobileAddress}</ShowUp>}
      {companyBookingInfo?.showProviders !== 2 && (
        <ShowUp untilTo="XLarge">
          <SliderAvatarEmployees hasSelectedServices={!!selectedServices} />
        </ShowUp>
      )}
    </St.CompanyInfoContainer>
  );
};
