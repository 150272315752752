import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Location } from '@agendapro/emerald-icons';
import { useRouter } from 'next/router';

import { GlobalContentLayout } from '@/components';
import { useLocation, useLocations } from '@/services/locations';
import { Paragraph, Select } from '@/UI';
import { AddressAndContact } from '../AddressAndContact';
import { CompanyInfo } from '../CompanyInfo';
import { Map } from '../Map';
import useIsIframe from '@/hooks/useIsIframe';
import usePageContext from '@/hooks/usePageContext';
import { useIframeRouter } from '@/hooks/useIframeRouter';
import * as St from './BranchOfficeHeader.styles';
import { useDialogsContext } from '@/context/DialogsContext';
import { useCompanyRRSS } from '@/services/company';

export const BranchOfficeHeader: FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const isIframe = useIsIframe();
  const { locationId, countryCode, providerId } = router.query as {
    locationId: string;
    countryCode: string;
    providerId: string;
  };

  const { companyOverview, queryParams } = usePageContext();
  const { routerPush } = useIframeRouter();
  const { data: currentLocation, isError, isLoading } = useLocation(locationId);
  const { setErrorDialog } = useDialogsContext();
  const { data: locationsData } = useLocations({ companyId: companyOverview?.id });
  const locations =
    locationsData?.map((location) => ({
      value: location.id,
      label: location.locationName || '',
      leftAdornment: {
        component: Location,
        size: 20,
        color: theme.migrateColors('icon.default', 'gray'),
      },
    })) || [];

  const { data: companyRRSS, isLoading: isLoadingCompanyRRSS } = useCompanyRRSS(companyOverview!.id);

  const onChangeOfficce = (office: typeof locations[number]) => {
    routerPush(`/${countryCode}/sucursal/${office.value}/${queryParams}`);
  };

  useEffect(() => {
    if (isError) {
      setErrorDialog({ open: isError });
    }
  }, [setErrorDialog, isError]);

  if (isLoading || !currentLocation) {
    return <St.CompanyInfoContainer />;
  }

  const collapsed = !currentLocation?.latitude && !currentLocation?.longitude;
  const hasDescription = !!(currentLocation?.locationDescription || companyOverview?.description);

  return (
    <St.CompanyInfoContainer>
      <GlobalContentLayout>
        <div>
          {currentLocation && isIframe && (
            <>
              <Paragraph weight="regular" size="sectionHeadline">
                {companyOverview?.name}
              </Paragraph>
              <div className="flex">
                <Paragraph weight="light" size="small" color={theme.migrateColors('text.subtler', 'gray50')}>
                  {currentLocation.locationName}
                </Paragraph>
                {locations.length > 1 && (
                  <Select
                    options={locations}
                    onChange={(selected) => onChangeOfficce(selected)}
                    type="LINK"
                    search={locations?.length > 5}
                  >
                    <Paragraph className="changeBranch" weight="light">
                      {`${t('CHANGE_OFFICE_BRANCH')}`}
                    </Paragraph>
                  </Select>
                )}
              </div>
            </>
          )}
        </div>
        <St.InfoWrapper collapsed={collapsed} hasRrss={!!companyRRSS?.length} hasDescription={hasDescription}>
          <CompanyInfo
            banner={currentLocation?.image || undefined}
            logo={companyOverview?.logo || ''}
            collapsed={collapsed}
            rrss={companyRRSS}
            locationDescription={currentLocation?.locationDescription}
            companyDescription={companyOverview?.description}
            isLoading={isLoadingCompanyRRSS}
            companyName={companyOverview?.name}
            mobileAddress={
              <AddressAndContact
                image={currentLocation?.imageMap || ''}
                locationId={locationId}
                address={currentLocation?.address || ''}
                phone={currentLocation?.phone || ''}
                times={currentLocation?.locationsTimes || []}
                outcall={!!currentLocation?.outcall}
                whatsappUrl={currentLocation?.whatsappUrl}
                whatsappActive={currentLocation?.whatsappActive}
                secondaryPhone={currentLocation?.secondaryPhone}
              />
            }
          />
          <St.MapContainer>
            <Map
              address={currentLocation?.detailedAddress || currentLocation?.address || ''}
              collapsed={collapsed}
              hasDescription={hasDescription}
              image={currentLocation?.imageMap || ''}
              locationId={locationId}
              outcall={!!currentLocation?.outcall}
              phone={currentLocation?.phone || ''}
              times={currentLocation?.locationsTimes || []}
              whatsappUrl={currentLocation.whatsappUrl}
              whatsappActive={currentLocation.whatsappActive}
              secondaryPhone={currentLocation?.secondaryPhone}
              hasBanner
            />
          </St.MapContainer>
        </St.InfoWrapper>
      </GlobalContentLayout>
    </St.CompanyInfoContainer>
  );
};
