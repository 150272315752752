import styled, { css } from 'styled-components';

import { devices } from '@/UI/Styles/Queries';

interface CompanyInfoProps {
  collapsed?: boolean;
  hasBanner: boolean;
}

export const CompanyInfoContainer = styled.div<CompanyInfoProps>`
  display: flex;
  flex-direction: column;
  width: ${({ hasBanner }) => (hasBanner ? '100%' : 'auto')};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.backgroundSecondary};
  border-radius: 18px;

  @media ${devices.XLarge} {
    ${({ hasBanner }) =>
      hasBanner &&
      css`
        width: calc(100% - 350px);
        background-color: unset;
        padding: 0;
      `}
  }

  .detailContainer {
    > p {
      text-align: justify;
    }
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
