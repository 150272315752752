import styled from 'styled-components';

import { devices } from '@/UI/Styles/Queries';
import { TextHeader } from '@/UI/Atoms/TextHeader';

export const Wrapper = styled.div`
  max-height: 146px;

  @media ${devices.XLarge} {
    margin-top: 12px;
    border-top: 1px solid ${({ theme }) => theme.tokens.color.border.primary};
    padding-top: 12px;
    max-height: unset;
  }
  .alert {
    .cardAlert {
      width: 375px;
      padding: ${({ theme }) => theme.spacing[32]};
    }
    .titleAlert {
      font-size: 18px;
    }
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  color: inherit;
  width: 18%;
  min-width: 18%;
  @media ${devices['no-mobile']} {
    width: 10%;
    min-width: 10%;
  }
  @media ${devices.XLarge} {
    width: unset;
    min-width: unset;
  }
`;

export const ProviderButton = styled.button`
  padding: 0;
  width: 22.22%;
  min-width: 22.22%;
  @media ${devices.Medium} {
    width: 11.76%;
    min-width: 11.76%;
  }
  @media ${devices.Large} {
    width: unset;
    min-width: unset;
  }
`;

export const SliderAvatarMobile = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
`;
