import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Location } from '@agendapro/emerald-icons';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { SimpleModal } from '@/UI/Molecules/SimpleModal';
import { AddressAndContactProps } from './AddressAndContact.types';
import { Map } from '../Map';

export const AddressAndContact: FC<AddressAndContactProps> = ({
  address,
  image,
  locationId,
  outcall,
  phone,
  times,
  whatsappUrl,
  whatsappActive,
  secondaryPhone,
}) => {
  const [visible, setVisible] = useState(false);
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="px-1 pt-1 max-h-[24px]">
      <button
        className="flex flex-row gap-2 items-center w-full max-h-[20px]"
        type="button"
        tabIndex={0}
        onClick={() => setVisible(true)}
        onKeyDown={() => setVisible(true)}
      >
        <Location size={18} color={palette.textLink} />
        <Paragraph>{t('ADDRESSANDCONTACT')}</Paragraph>
      </button>
      <SimpleModal visible={visible} title={t('ADDRESSANDCONTACT')} onClose={() => setVisible(false)}>
        <Map
          address={address}
          image={image}
          locationId={locationId}
          outcall={outcall}
          phone={phone}
          times={times}
          whatsappUrl={whatsappUrl}
          viewType="MODAL"
          whatsappActive={whatsappActive}
          secondaryPhone={secondaryPhone}
        />
      </SimpleModal>
    </div>
  );
};
